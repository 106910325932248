import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import Input from '@shared/ui/inputs/Input';
import useNicknameCreation from '@entities/profile/api/useNicknameCreation';
import Button from '@shared/ui/buttons/Button';
import { StepFormContainer, Title } from '@widgets/sidebars/shared';
import { LocationSelect } from '@features/countries';
import ThrottleInput from '@shared/ui/inputs/ThrottleInput';
import { introductionValidationSchema } from './validation';
import { InputContainer, StepBodyText as StepBodyTextComponent, } from '../shared';
const StepBodyText = styled(StepBodyTextComponent)(props => ({
    marginBottom: props.theme.spacing_sizes.l,
}));
const Introduction = ({ goToNextStep, initValues, }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const theme = useTheme();
    const { control, getValues, handleSubmit, setError, formState: { errors }, trigger, } = useForm({
        defaultValues: initValues,
        resolver: yupResolver(introductionValidationSchema(t)),
    });
    const { nicknameInputStatus, nicknameOnValidate, findByNicknameState, } = useNicknameCreation({
        getValues,
        setError,
        trigger,
        skipEmptyValue: true,
    });
    const isDisabled = findByNicknameState.isFetching || findByNicknameState.isLoading;
    return (_jsx(StepFormContainer, { title: (_jsx(Title, { children: t('profile.create_trader.steps.introduction.title', { ns: 'common' }) })), onSubmit: handleSubmit(goToNextStep), content: (_jsxs(_Fragment, { children: [_jsx(StepBodyText, { children: t('profile.create_trader.steps.introduction.description', { ns: 'common' }) }), _jsx(InputContainer, { haveNotifications: Boolean((_a = errors.displayName) === null || _a === void 0 ? void 0 : _a.message), children: _jsx(Controller, { name: 'displayName', control: control, render: ({ field, fieldState }) => {
                            var _a, _b;
                            return (_jsx(Input, Object.assign({}, field, { label: t('profile.create_trader.steps.introduction.publicname.title', { ns: 'common' }), notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, required: true })));
                        } }) }), _jsx(InputContainer, { haveNotifications: Boolean((_b = errors.location) === null || _b === void 0 ? void 0 : _b.message), children: _jsx(Controller, { name: 'location', control: control, render: ({ field, fieldState }) => {
                            var _a, _b;
                            return (_jsx(LocationSelect, { value: field.value, onChange: field.onChange, onBlur: field.onBlur, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, label: t('profile.create_trader.steps.introduction.location.title', { ns: 'common' }), placeholder: t('profile.create_trader.steps.introduction.location.placeholder', { ns: 'common' }), required: true, fullWidth: true }));
                        } }) }), _jsx(InputContainer, { haveNotifications: Boolean(((_c = errors.username) === null || _c === void 0 ? void 0 : _c.message) || (nicknameInputStatus === null || nicknameInputStatus === void 0 ? void 0 : nicknameInputStatus.text)), children: _jsx(Controller, { name: 'username', control: control, render: ({ field, fieldState }) => {
                            var _a, _b;
                            return (_jsx(ThrottleInput, { label: (t('profile.create_trader.steps.introduction.nickname.title', { ns: 'common' })), placeholder: t('profile.create_trader.steps.introduction.nickname.placeholder', { ns: 'common' }), value: field.value, onChange: field.onChange, onBlur: field.onBlur, searchFn: nicknameOnValidate(), throttleDelay: 1000, state: nicknameInputStatus === null || nicknameInputStatus === void 0 ? void 0 : nicknameInputStatus.status, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, message: nicknameInputStatus === null || nicknameInputStatus === void 0 ? void 0 : nicknameInputStatus.text, startAdornment: '@', fullWidth: true, required: true }));
                        } }) })] })), contentJustify: 'start', contentFlex: 1, containerGap: theme.spacing_sizes.s * 10, buttons: (_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, type: 'submit', disabled: isDisabled, children: t('profile.create_trader.steps.introduction.btn_text', { ns: 'common' }) })) }));
};
export default Introduction;
