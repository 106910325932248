import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import Input from '@shared/ui/inputs/Input';
import Button from '@shared/ui/buttons/Button';
import { StepFormContainer, Title } from '@widgets/sidebars/shared';
import { StepBodyText as StepBodyTextComponent, StepTitleCounter, } from '../shared';
const InputContainer = styled.div(props => ({
    width: '100%',
    marginBottom: `${props.theme.spacing_sizes.l}px`,
}));
const StepBodyText = styled(StepBodyTextComponent)(props => ({
    width: '100%',
    marginBottom: `${props.theme.spacing_sizes.xs * 4}px`,
}));
const PersonalDetails = ({ initValues, goToNextStep }) => {
    const { t } = useTranslation();
    const { control, handleSubmit, } = useForm({ defaultValues: initValues });
    return (_jsx(StepFormContainer, { title: (_jsxs(Title, { children: [_jsx(StepTitleCounter, { children: "1/4" }), t('profile.create_trader.steps.personal_details.title', { ns: 'common' })] })), content: (_jsxs(_Fragment, { children: [_jsx(StepBodyText, { children: t('profile.create_trader.steps.personal_details.description', { ns: 'common' }) }), _jsx(InputContainer, { children: _jsx(Controller, { name: 'firstName', control: control, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { label: t('profile.create_trader.steps.personal_details.first_name.title', { ns: 'common' }) }))) }) }), _jsx(InputContainer, { children: _jsx(Controller, { name: 'lastName', control: control, render: ({ field }) => (_jsx(Input, Object.assign({}, field, { label: t('profile.create_trader.steps.personal_details.last_name.title', { ns: 'common' }) }))) }) })] })), buttons: (_jsx(Button, { type: 'submit', variant: 'filled', color: 'primary', size: 'large', fullWidth: true, children: t('profile.create_trader.steps.personal_details.continue_btn_text', { ns: 'common' }) })), onSubmit: handleSubmit(goToNextStep) }));
};
export default PersonalDetails;
