import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { StepContainer, Title } from '@widgets/sidebars/shared';
import ItemsList from '@shared/ui/display/ItemsList';
import Button from '@shared/ui/buttons/Button';
import Icon from '@shared/ui/icons/Icon';
import { ReactComponent as BecomeTraderIcon } from '@icons/custom/become-trader.svg';
const IconContainer = styled.div(props => ({
    marginBottom: props.theme.spacing_sizes.xs * 8.75,
}));
const Initial = ({ goToNextStep, }) => {
    const { t } = useTranslation();
    const handleBtnClick = () => {
        if (goToNextStep) {
            goToNextStep();
        }
    };
    return (_jsx(StepContainer, { title: (_jsx(Title, { children: t('profile.create_trader.steps.initial.title', { ns: 'common' }) })), content: (_jsxs(_Fragment, { children: [_jsx(IconContainer, { children: _jsx(Icon, { IconComponent: BecomeTraderIcon, keepOriginalColor: true, size: 200 }) }), _jsx(ItemsList, { list: t('profile.create_trader.steps.initial.list', { returnObjects: true, ns: 'common' }) || [], listItemStyles: {
                        maxWidth: 410,
                        alignItems: 'baseline',
                        letterSpacing: '0.16px',
                    } })] })), buttons: (_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, onClick: handleBtnClick, children: t('profile.create_trader.steps.initial.btn_text', { ns: 'common' }) })) }));
};
export default Initial;
